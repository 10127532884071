<footer>
    <div class="container">
        <div class="footer">
            <div class="col-5 col-md">
                <div class="logo">
                    MEET + GREET
                    <div class="footer-second col-md">
                        <div class="footer-second-item">
                            <h6>АО «НИРИН</h6>
                        </div>
                        <div class="footer-second-item">
                            <h6>ОГРН: </h6>
                            <p>1237700519924</p>
                        </div>
                        <div class="footer-second-item">
                            <h6>ИНН: </h6>
                            <p>7734481950</p>
                        </div>
                        <div class="footer-second-item">
                            <h6>Телефон: </h6>
                            <p>+7 919 108 00 88</p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-4 col-md-1">
                <app-switcher/>
            </div>
            <div class="col-3 d-none d-md-block">
                <app-social-media [vertical]="false" [buttonJustifyContent]="'flex-end'"/>
            </div>
        </div>
        <div class="footer-second footer-third">
            <div class="footer-second-item">
                <h6>АО «НИРИН</h6>
            </div>
            <div class="footer-second-item">
                <h6>ОГРН: </h6>
                <p>1237700519924</p>
            </div>
            <div class="footer-second-item">
                <h6>ИНН: </h6>
                <p>7734481950</p>
            </div>
            <div class="footer-second-item">
                <h6>Телефон: </h6>
                <p>+7 919 108 00 88</p>
            </div>
        </div>
    </div>
</footer>
<app-booking-form/>
<app-modal-menu/>
