<app-section [sectionClass]="'section-white section-auto'">
    <div class="airport-container">
        <div class="title">
            <h1 class="colorYellow">
                {{ languageService.getNameByTheLang(airport?.names) }}
            </h1>
            <app-button
                    [buttonTitle]="textElements[1]"
                    [buttonClass]="'button-style-2 button-size-l'"
                    [buttonJustifyContent]="'start'"
                    [routerLink]="'/airports'"
            />
        </div>
        <div class="content-container">
            <div *ngFor="let service of airportServices">
                <div class="service-container" [@openClose]="service.open ? 'open' : 'closed'">
                    <h2 class="colorGray">{{ languageService.getNameByTheLang(service.title) }}</h2>
                    <div class="tier-container">
                        <div class="tier-item" *ngFor="let tier of service.tiers">
                            <div class="tier-item-title">
                                <app-icon [icon]="getIcon(tier.type)"
                                          [width]="60" [height]="60"
                                />
                                <h3><app-text-element [inputText]="TypeOfSServiceToTextElement(tier.type)"/></h3>
                            </div>
                            <div class="tier-item-costs">
                                <div class="tier-item-cost">
                                    <p><app-text-element [inputText]="textElements[10]"/></p>
                                    <p><app-text-element [inputText]="textElements[30]"/>{{ languageService.getPrice(tier.costForOne) }}</p>
                                </div>
                                <div class="tier-item-cost align-items-end">
                                    <p style="text-align: right;"><app-text-element [inputText]="textElements[11]"/></p>
                                    <p><app-text-element [inputText]="textElements[30]"/>{{ languageService.getPrice(tier.costForSubsequent) }}</p>
                                </div>
                            </div>
                            <div [@hideText]="service.open ? 'appeared' : 'hidden'">
                                <div class="tier-description-container">
                                    <div class="tier-description-item" *ngFor="let description of tier.description">
                                        <h5><app-text-element [inputText]="description.title"/></h5>
                                        <p><app-text-element [inputText]="description.text"/></p>
                                    </div>
                                </div>
                                <div class="button-container">
                                    <app-button
                                            [buttonTitle]="textElements[12]"
                                            [buttonClass]="'button-style-1 button-size-m'"
                                            [buttonJustifyContent]="'flex-start'"
                                            (click)="bookingService.setBookingForm(true)"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
<!--                    <div class="terms-and-conditions-container">-->
<!--                        <p *ngFor="let item of service.termsAndConditions" >-->
<!--                            <app-text-element [inputText]="item"/>-->
<!--                        </p>-->
<!--                    </div>-->
                </div>
                <div class="show-more-container">
                    <app-button
                            [buttonTitle]="!service.open ? textElements[17] : textElements[18]"
                            [buttonClass]="'button-style-1 button-size-m'"
                            (click)="service.open = !service.open"
                    />
                </div>
            </div>
            <div class="terms-and-conditions-container">
<!--                <p *ngFor="let item of mockTermsAndConditions" >-->
<!--                    <app-text-element [inputText]="item"/>-->
<!--                </p>-->
                <p>{{ languageService.getNameByTheLang(mainInfo)}}</p>
            </div>
            <div class="info-container">
                <div class="col-9">
                    <div class="info-map-container">
                        <app-map *ngIf="displayMap" [lat]="airport?.lat ?? 0"  [lng]="airport?.lng ?? 0"/>
                    </div>
                </div>
                <div class="col-3">
                    <div class="info-text-container">
                        <div *ngIf="airport?.countryObject?.names && airport?.cityObject?.names" class="info-text-item">
                            <h3 class="colorYellow cormorant"><app-text-element [inputText]="textElements[19]"/></h3>
                            <p>{{ languageService.getNameByTheLang(airport?.countryObject?.names) }},
                                {{ languageService.getNameByTheLang(airport?.cityObject?.names) }}</p>
                        </div>
                        <div *ngIf="airport?.iata_code" class="info-text-item">
                            <h3 class="colorYellow cormorant"><app-text-element [inputText]="textElements[20]"/></h3>
                            <p>{{ airport?.iata_code }}</p>
                        </div>
                        <div *ngIf="airport?.icao_code" class="info-text-item">
                            <h3 class="colorYellow cormorant"><app-text-element [inputText]="textElements[21]"/></h3>
                            <p>{{ airport?.icao_code }}</p>
                        </div>
                        <div *ngIf="airport?.lat && airport?.lng" class="info-text-item">
                            <h3 class="colorYellow cormorant"><app-text-element [inputText]="textElements[22]"/></h3>
                            <p>{{ airport?.lat }}, {{ airport?.lng }}</p>
                        </div>
                        <div *ngIf="airport?.timezone" class="info-text-item">
                            <h3 class="colorYellow cormorant"><app-text-element [inputText]="textElements[23]"/></h3>
                            <p>{{ airport?.timezone }}</p>
                        </div>
                        <div *ngIf="airport?.website" class="info-text-item">
                            <h3 class="colorYellow cormorant"><app-text-element [inputText]="textElements[24]"/></h3>
                            <a [href]="airport?.website" target="_blank">
                                {{ airport?.website ?
                                airport.website.replace("https://", "").replace("http://", "")
                                : "" }}
                            </a>
                        </div>
                        <div *ngIf="airport?.phone" class="info-text-item">
                            <h3 class="colorYellow cormorant"><app-text-element [inputText]="textElements[25]"/></h3>
                            <p>{{ airport?.phone }}</p>
                        </div>
                        <div *ngIf="airport?.facebook" class="info-text-item">
                            <h3 class="colorYellow cormorant"><app-text-element [inputText]="textElements[26]"/></h3>
                            <a [href]="'http://' + airport?.facebook" target="_blank">
                                {{ airport?.facebook }}
                            </a>
                        </div>
                        <div *ngIf="airport?.instagram" class="info-text-item">
                            <h3 class="colorYellow cormorant"><app-text-element [inputText]="textElements[27]"/></h3>
                            <a [href]="'http://' + airport?.instagram" target="_blank">
                                {{ airport?.instagram }}
                            </a>
                        </div>
                        <div *ngIf="airport?.linkedin" class="info-text-item">
                            <h3 class="colorYellow cormorant"><app-text-element [inputText]="textElements[28]"/></h3>
                            <a [href]="'http://' + airport?.linkedin" target="_blank">
                                {{ airport?.linkedin }}
                            </a>
                        </div>
                        <div *ngIf="airport?.twitter" class="info-text-item">
                            <h3 class="colorYellow cormorant"><app-text-element [inputText]="textElements[29]"/></h3>
                            <a [href]="'http://' + airport?.twitter" target="_blank">
                                {{ airport?.twitter }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-section>
